import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/choosing-perfect-san-diego-neighborhood"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_Choosing the Perfect San Diego Neighborhood for Your Lifestyle.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                You know you want to live in San Diego, but with so many neighborhoods in this
                Southern California city, how do you know which is right for you? Whether you’re
                looking for a family-friendly area, an exciting urban environment, quiet beachfront
                living, or your own luxurious slice of heaven, our San Diego neighborhood guide
                helps you find the perfect place for you.
              </CommonParagraph>
              <Heading type="h2">The Best Neighborhoods for Families</Heading>
              <CommonParagraph>
                The quiet residential neighborhood of{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/allied-gardens-homes-for-sale"
                  text="Allied Gardens"
                />{" "}
                is an ideal place to raise a family. It not only offers excellent schools, parks,
                and a strong sense of community but also gorgeous ranch-style homes.
              </CommonParagraph>
              <CommonParagraph>
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/rancho-bernardo-homes-for-sale"
                  text="Rancho Bernardo"
                />{" "}
                is also an excellent community for families, offering a great mix of activities and
                recreational outdoor areas. If you enjoy spending time in the great outdoors with
                your family, you’ll love the proximity to nearby Lake Hodges. From fishing and
                hiking to boating and kayaking, the lake is a great place to spend a Saturday with
                the kids.
              </CommonParagraph>
              <Heading type="h2">Areas that Appeal to Young Professionals</Heading>
              <CommonParagraph>
                As the heart of the city’s cultural scene,{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/downtown-san-diego-homes-for-sale"
                  text="Downtown San Diego"
                />{" "}
                is a popular place for young professionals. From its high-rise living, hub of
                businesses, and abundance of nightlife options, it’s a fantastic, walkable area
                complete with entertainment venues and restaurants.
              </CommonParagraph>
              <CommonParagraph>
                If you’re looking for cool neighborhoods in San Diego,{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/little-italy-homes-for-sale"
                  text="Little Italy"
                />{" "}
                should be on your list! It’s another hotspot for young professionals, with its mix
                of historic charm and modern urban living. Known for its art galleries, food scene,
                and events like the Little Italy Mercato Farmers’ Market, it’s a trendy place full
                of social opportunities.
              </CommonParagraph>
              <Heading type="h2">Beach Lovers Paradise</Heading>
              <CommonParagraph>
                Drawn to the ocean?{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/mission-beach-homes-for-sale"
                  text="Mission Beach"
                />{" "}
                may be the perfect place for you to call home. From its iconic boardwalk and
                laid-back vibe, this coastal destination is ideal for anyone who loves surfing,
                sunbathing, or simply enjoying life by the water.
              </CommonParagraph>
              <CommonParagraph>
                Another great option for life by the water is{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/pacific-beach-homes-for-sale"
                  text="Pacific Beach"
                />
                , known by locals as “PB.” Its mix of cafes, restaurants, and bars by the water
                makes it a popular spot for young adults and surfers. If you enjoy sandy shores and
                a lively social scene, be sure to explore homes in PB!
              </CommonParagraph>
              <Heading type="h2">For Those Looking for Luxury</Heading>
              <CommonParagraph>
                Ready to live the high life? Put{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/coronado-homes-for-sale"
                  text="Coronado"
                />{" "}
                on your list of potential neighborhoods to call home. Known for its proximity to the
                iconic Hotel del Coronado, upscale homes, and overall resort-like atmosphere, this
                area offers a small-town feel while still being close to downtown. The beautiful
                beaches and tree-lined streets help make Coronado feel even more upscale.
              </CommonParagraph>
              <CommonParagraph>
                If a high-end coastal lifestyle is what you’re after, don’t miss Point Loma. This
                historic neighborhood offers breathtaking views of the San Diego Bay and is home to
                a number of luxury properties. From the nearby marina to the natural beauty and
                serene atmosphere,{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/point-loma-homes-for-sale"
                  text="Point Loma"
                />{" "}
                is a top choice for many high earners.
              </CommonParagraph>
              <Heading type="h2">Find Your Home Sweet Home</Heading>
              <CommonParagraph>
                No matter your lifestyle, there’s a welcoming San Diego community for you. Let The
                Selby Team be your guide, introducing you to the neighborhoods that offer not just
                the homes you’re looking for but the amenities and community.{" "}
                <ContactSlideoutLink text="Contact our team" /> of San Diego realtors for an
                enjoyable, personalized shopping and buying experience!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
